// src/components/FounderMessage.js

import React from "react";

const FounderMessage = () => {
  return (
    <div className='bg-blue-100 py-12 px-4 text-center'>
      <div className='max-w-3xl mx-auto'>
        <h2 className='text-3xl font-bold mb-4'>Message from Our Founder</h2>
        <p className='text-lg mb-4'>
          As the founder of Eduteach Education Services, I am deeply honored to
          be part of a transformative journey in education. Our mission is to
          empower learners with the skills and knowledge they need to succeed in
          an ever-evolving world.
        </p>
        <blockquote className='italic text-gray-700 mb-4'>
          "Education is the key to unlocking a brighter future. At Eduteach, we
          are committed to providing innovative and accessible learning
          solutions that inspire and equip students for lifelong success. I am
          excited to share our latest initiatives and to partner with you in
          this journey of growth and discovery."
        </blockquote>
        <p>
          Explore our offerings and stay connected through our website:
          <a
            href='https://eduteacheducation.com'
            className='text-blue-500 underline'
          >
            {" "}
            eduteacheducation.com
          </a>
          . <br /> Together, let’s shape the future of education!
        </p>
        <p className='font-semibold mt-2'>— Palash Kaushik</p>
        <p className='text-sm'>Founder & CEO, Eduteach Education Services</p>
      </div>
    </div>
  );
};

export default FounderMessage;
