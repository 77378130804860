import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { navLinks } from "../../ProjectData"; // Adjust the import path as necessary

const AddImages = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [categories, setCategories] = useState([]);
  const [subitems, setSubitems] = useState([]);
  const selectedCategory = watch("category");

  useEffect(() => {
    // Include "Homepage" and filter categories to include only those without items
    const filteredCategories = [
      "Homepage",
      ...navLinks
        .filter((link) => !link.items || link.items.length === 0)
        .map((link) => link.name),
    ];
    setCategories(filteredCategories);
  }, []);

  useEffect(() => {
    // Find the selected category and set its subitems
    const categoryData = navLinks.find(
      (link) => link.name === selectedCategory
    );
    if (categoryData && categoryData.items) {
      setSubitems(categoryData.items.flatMap((item) => item.name));
      setValue("subitem", ""); // Reset selected subitem
    } else {
      setSubitems([]); // Reset subitems if category has no items
    }
  }, [selectedCategory, setValue]);

  const onSubmit = (data) => {
    // Handle form submission logic here
    console.log(data);
  };

  return (
    <div>
      <h2 className='text-2xl font-bold mb-4'>Add New Images</h2>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
        <div>
          <label
            htmlFor='category'
            className='block text-sm font-medium text-gray-700'
          >
            Pages
          </label>
          <select
            id='category'
            {...register("category", { required: "Category is required" })}
            className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          >
            <option value=''>Select Page</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          {errors.category && (
            <p className='text-red-500 text-sm'>{errors.category.message}</p>
          )}
        </div>

        <div>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            Title
          </label>
          <input
            id='title'
            type='text'
            {...register("title", { required: "Title is required" })}
            className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          />
          {errors.title && (
            <p className='text-red-500 text-sm'>{errors.title.message}</p>
          )}
        </div>

        <div>
          <label
            htmlFor='description'
            className='block text-sm font-medium text-gray-700'
          >
            Description
          </label>
          <textarea
            id='description'
            rows='4'
            {...register("description")}
            className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          />
        </div>

        <div>
          <label
            htmlFor='image'
            className='block text-sm font-medium text-gray-700'
          >
            Image
          </label>
          <input
            id='image'
            type='file'
            accept='image/*'
            {...register("image")}
            className='mt-1 p-2 block w-full text-sm text-gray-500 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
          />
        </div>

        <button
          type='submit'
          className='px-4 py-2 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700'
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddImages;
