import React, { useState } from "react";
import { staticData } from "../ProjectData";

// Define static data for the given category, subcategory, and item

const Modal = ({ isOpen, onClose, image, title, description }) => {
  if (!isOpen) return null;

  return (
    <div
      className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'
      onClick={onClose}
    >
      <div
        className='bg-white p-4 rounded-lg shadow-lg flex max-w-4xl'
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={image}
          alt='Modal Image'
          className='w-1/2 h-auto object-cover rounded'
        />
        <div className='ml-4 w-1/2'>
          <h3 className='text-xl font-semibold'>{title}</h3>
          <p className='text-lg mt-2'>{description}</p>
        </div>
        <button
          className='absolute top-4 right-4 text-xl font-bold'
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

const CategoryPage = ({ category, subCategory, item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");

  // Select data based on category, subCategory, and item
  const data = staticData[category]?.[subCategory]?.[item] ||
    staticData[category]?.[subCategory] ||
    staticData[category] || {
      description: "No data available.",
      images: [],
    };

  const handleImageClick = (image, title, description) => {
    setModalImage(image);
    setModalTitle(title);
    setModalDescription(description);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalImage("");
    setModalTitle("");
    setModalDescription("");
  };

  return (
    <div className='container mx-auto p-5'>
      <h3 className='text-lg font-bold mt-2 text-center'>
        <span className='text-lg'>{category}</span>>{" "}
        <span className='text-md'>{subCategory}</span>>{" "}
        <span className='text-sm'>{item}</span>
      </h3>

      {data.images && data.images.length > 0 && (
        <div className='mt-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1 p-1 rounded-xl'>
          {data.images.map((image, index) => (
            <div key={index} className='relative'>
              <img
                src={image.url}
                alt={`${item} ${index + 1}`}
                className='w-full h-[350px] object-fit cursor-pointer border shadow-xl rounded-xl hover:shadow-2xl hover:scale-95 duration-700'
                onClick={() =>
                  handleImageClick(image.url, image.title, image.description)
                }
              />
              <div className='absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 w-full'>
                <h4 className='text-sm font-semibold'>{image.title}</h4>
                <p className='text-xs'>{image.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        image={modalImage}
        title={modalTitle}
        description={modalDescription}
      />
    </div>
  );
};

export default CategoryPage;
