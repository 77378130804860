import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { navLinks } from "../../ProjectData"; // Adjust the import path as necessary

const AddProduct = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [categories, setCategories] = useState([]);
  const [subitems, setSubitems] = useState([]);
  const [nestedSubitems, setNestedSubitems] = useState([]);
  const [selectedNestedSubitem, setSelectedNestedSubitem] = useState("");

  const selectedCategory = watch("category");
  const selectedSubitem = watch("subitem");

  useEffect(() => {
    // Filter categories to include only those with items.length > 0
    const filteredCategories = navLinks
      .filter((link) => link.items && link.items.length > 0)
      .map((link) => link.name);
    setCategories(filteredCategories);
  }, []);

  useEffect(() => {
    // Find the selected category and set its subitems
    const categoryData = navLinks.find(
      (link) => link.name === selectedCategory
    );
    if (categoryData && categoryData.items) {
      setSubitems(categoryData.items.flatMap((item) => item.name));
      setValue("subitem", ""); // Reset selected subitem
      setNestedSubitems([]); // Reset nested subitems when category changes
    } else {
      setSubitems([]);
    }
  }, [selectedCategory, setValue]);

  useEffect(() => {
    // Find the selected subitem and set its nested subitems
    const categoryData = navLinks.find(
      (link) => link.name === selectedCategory
    );
    if (categoryData && categoryData.items) {
      const subitemData = categoryData.items.find(
        (item) => item.name === selectedSubitem
      );
      if (subitemData && subitemData.nested) {
        setNestedSubitems(subitemData.nested);
      } else {
        setNestedSubitems([]);
      }
    } else {
      setNestedSubitems([]);
    }
  }, [selectedSubitem, selectedCategory]);

  const handleNestedSubitemChange = (e) => {
    setSelectedNestedSubitem(e.target.value);
  };

  const onSubmit = (data) => {
    // Handle form submission logic here
    console.log(data);
  };

  return (
    <div>
      <h2 className='text-2xl font-bold mb-4'>Add New Product</h2>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
        <div>
          <label
            htmlFor='category'
            className='block text-sm font-medium text-gray-700'
          >
            Category
          </label>
          <select
            id='category'
            {...register("category", { required: "Category is required" })}
            className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          >
            <option value=''>Select Category</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          {errors.category && (
            <p className='text-red-500 text-sm'>{errors.category.message}</p>
          )}
        </div>

        {selectedCategory && (
          <div>
            <label
              htmlFor='subitem'
              className='block text-sm font-medium text-gray-700'
            >
              Subitem
            </label>
            <select
              id='subitem'
              {...register("subitem")}
              className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
            >
              <option value=''>Select Subitem</option>
              {subitems.map((subitem) => (
                <option key={subitem} value={subitem}>
                  {subitem}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedSubitem && (
          <div>
            <label
              htmlFor='nestedSubitem'
              className='block text-sm font-medium text-gray-700'
            >
              Existing Nested Subitems
            </label>
            <select
              id='nestedSubitem'
              value={selectedNestedSubitem}
              onChange={handleNestedSubitemChange}
              className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
            >
              <option value=''>Select Nested Subitem</option>
              {nestedSubitems.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        )}

        <div>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            Title
          </label>
          <input
            id='title'
            type='text'
            {...register("title", { required: "Title is required" })}
            className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          />
          {errors.title && (
            <p className='text-red-500 text-sm'>{errors.title.message}</p>
          )}
        </div>

        <div>
          <label
            htmlFor='description'
            className='block text-sm font-medium text-gray-700'
          >
            Description
          </label>
          <textarea
            id='description'
            rows='4'
            {...register("description")}
            className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          />
        </div>

        <div>
          <label
            htmlFor='image'
            className='block text-sm font-medium text-gray-700'
          >
            Image
          </label>
          <input
            id='image'
            type='file'
            accept='image/*'
            {...register("image")}
            className='mt-1 p-2 block w-full text-sm text-gray-500 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
          />
        </div>

        <button
          type='submit'
          className='px-4 py-2 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700'
        >
          Submit
        </button>
      </form>

      {nestedSubitems.length > 0 && (
        <div className='mt-8'>
          <h3 className='text-xl font-bold mb-4'>Nested Subitems</h3>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
            {nestedSubitems.map((item, index) => (
              <div
                key={index}
                className='p-4 border border-gray-200 rounded-lg shadow-sm bg-white'
              >
                <h4 className='text-lg font-semibold mb-2'>{item}</h4>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddProduct;
