import React from "react";
import { FaHistory, FaLightbulb, FaStar } from "react-icons/fa";
import FounderMessage from "../utils/FounderMessage";

const About = () => {
  const topImage =
    "https://static.vecteezy.com/system/resources/previews/016/765/134/non_2x/about-us-button-web-banner-templates-illustration-vector.jpg";
  const historyImage =
    "https://miro.medium.com/v2/resize:fit:823/1*ujO8hBPWV64XStbmn8o5QA.jpeg";
  const innovationImage =
    "https://images.inc.com/uploaded_files/image/1920x1080/getty_691886452_405128.jpg";
  const premiumQualityImage =
    "https://static.vecteezy.com/system/resources/thumbnails/006/552/826/small_2x/premium-quality-product-100-percen-original-logo-template-illustration-free-vector.jpg";

  const sections = [
    {
      title: "History",
      icon: <FaHistory className='text-4xl text-white mr-4' />,
      text: [
        "Crown Hardware was established under the guidance of Swaroop Group and we have a long and distinguished family heritage. We're based in Aligarh, in the historic heart of the Indian lock and hardware industry.",
        "We offer a comprehensive range of Indian manufactured products, all made by hand by our highly experienced workforce. By combining traditional skills with modern techniques, our craftsmen produce quality fittings that are built to stand the test of time.",
        "Each item is traditionally made and finished by hand, making it unique. Every item is individually quality checked before leaving our factory to ensure perfection.",
      ],
      image: historyImage,
      reverse: true,
    },
    {
      title: "Premium Quality",
      icon: <FaStar className='text-4xl text-black mr-4' />,
      text: [
        "Crown Hardware prides itself on offering world-class custom gate, door, and cabinet hardware. Our clients come from around the globe for their unique and high-quality hardware needs.",
        "We replicate designs or create custom items to exact specifications using a variety of finishes, matching aged patina or specific architectural styles.",
        "Our products have earned worldwide fame for their premium quality and durability, with antique designs that draw attention to the often-overlooked elements of your home.",
      ],
      image: premiumQualityImage,
      reverse: false,
    },
    {
      title: "Innovation",
      icon: <FaLightbulb className='text-4xl text-white mr-4' />,
      text: [
        "We're always looking at ways to continually evolve and expand our product collections. This involves using our collection of patterns and dies that have been with our family-run business for generations.",
        "At Crown Hardware, we monitor stock levels to ensure that supplies of our most popular items are always available. This allows us to react quickly to customer orders and inquiries.",
        "We blend traditional craftsmanship with modern approaches to provide the highest quality products in the shortest lead times.",
      ],
      image: innovationImage,
      reverse: true,
    },
  ];

  return (
    <div className='mt-[100px] md:mt-[200px]'>
      {/* Top Image */}
      <div className='w-full '>
        <img
          src={topImage}
          alt='Crown Hardware Banner'
          className='w-full h-[200px] md:h-[300px] object-cover'
        />
      </div>

      {/* Sections */}
      <FounderMessage />
    </div>
  );
};

export default About;
