import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaTools, FaShieldAlt, FaBuilding, FaBullhorn } from "react-icons/fa";

import {
  FaIndustry,
  FaHandshake,
  FaUserShield,
  FaRecycle,
} from "react-icons/fa";

const cardData = [
  {
    title: "Products",
    description:
      "Our company manufactures a diverse range of builder's hardware to meet various needs. We offer padlocks, door stoppers, hinges, and a wide selection of locks including cylindrical locks and night latches. Our product range also includes lever handles, door locks, sinks, and tubs. Each item is crafted from high-quality metals such as brass, aluminum, iron, copper, and stainless steel. We ensure that our products are both durable and reliable, catering to both residential and commercial applications.",
    icon: <FaTools className='text-2xl ' />,
  },
  {
    title: "Quality",
    description:
      "At Crown Hardware, quality is not just a standard but a commitment. We subject each product to rigorous quality control measures to ensure it meets international standards. Our quality assurance process includes detailed inspections and testing to confirm product reliability and durability. We use state-of-the-art technology to enhance our quality checks. This dedication to quality helps us maintain a competitive edge and ensures customer satisfaction across all our offerings.",
    icon: <FaShieldAlt className='text-2xl ' />,
  },
  {
    title: "Infrastructure",
    description:
      "Our manufacturing facility boasts advanced infrastructure designed to support high-quality production. We have dedicated areas for Casting, Forging, and Finishing, as well as specialized equipment for Plating and Powder Coating. Additionally, our in-house Zinc Metal Casting unit allows us to manage all aspects of production internally. Our facility is equipped with modern machinery operated by skilled engineers, ensuring precision and efficiency in every product we create. This infrastructure supports our goal of delivering top-notch hardware solutions.",
    icon: <FaBuilding className='text-2xl ' />,
  },
  {
    title: "Goal",
    description:
      "Our primary goal is to continually offer innovative and high-quality products that meet the diverse needs of our customers. We strive to maintain and enhance our reputation as a leading brand in the builders' hardware industry. By focusing on quality and customer satisfaction, we aim to build long-term relationships with our clients. Our vision is to adapt to evolving market trends and customer preferences while staying true to our commitment to excellence. We are dedicated to sustaining our position as a trusted and reliable brand in the market.",
    icon: <FaBullhorn className='text-2xl ' />,
  },
];

const CompanyInfo = () => {
  const [selectedCard, setSelectedCard] = useState(cardData[0]);

  return (
    <div className='py-1'>
      <div className='container mx-auto px-4 md:px-8 mb-5'>
        <h2 className='text-2xl md:text-2xl  font-bold '>
          Welcome To Crown Hardware
        </h2>
        <p className='mt-4 text-gray-600 text-justify md:text-center'>
          With its principled approach towards customer satisfaction and an
          experience of many years in the field, the company has gained
          international recognition in the Builder Hardware industry.
        </p>
        <p className='mt-1 text-gray-600 mb-5 text-justify md:text-center'>
          Crown Hardware specializes in introducing innovative designs that add
          to the range of builder hardware components that we offer to the
          market.
        </p>
        <div className='flex flex-col md:flex-row gap-4'>
          {/* Title List */}
          <div className='md:w-1/3 space-y-4'>
            {cardData.map((card, index) => (
              <div
                key={index}
                className={`cursor-pointer p-4 flex items-center space-x-3 border rounded-lg ${
                  selectedCard.title === card.title
                    ? "bg-black text-white border-blue-300 shadow-xl"
                    : "bg-white border-gray-300"
                }`}
                onClick={() => setSelectedCard(card)}
              >
                {card.icon}
                <h3 className='text-md font-semibold '>{card.title}</h3>
              </div>
            ))}
          </div>

          {/* Description Display */}
          <div className='md:w-2/3 pl-2 border p-2 shadow-xl bg-gray-100 rounded-xl'>
            <h2 className='text-2xl md:text-2xl font-semibold text-black mb-4'>
              {selectedCard.title}
            </h2>
            <p className='text-black text-justify text-md'>
              {selectedCard.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
